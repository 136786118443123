












































































import { Component, Vue, Watch, Prop, Model, ModelSync } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { dispatchCheckApiError } from '@/store/main/actions';
import { ITestResult, IXIBCSample, IAnalyteValue, IAnnotation, IFile } from '@/interfaces';
import { format, parseISO } from 'date-fns';

import { Socket } from 'vue-socket.io-extended';
import { api } from '@/api';

// import { formatTime } from '@/utils'


import { v4 as uuidv4 } from 'uuid';

@Component
export default class ClinicalArchive extends Vue {
  @ModelSync('value', 'change', { type: Array })
  public readonly selectedTests!: IXIBCSample[];

  public nodeSelect: object[] = [
    { text: '0 Nodes', value: 0},
    { text: '1-3 Nodes', value: 1},
  ];

  public tumorSelect: object[] = [
    { text: '<= 10mm', value: 1},
    { text: '>10mm <=20mm', value: 2},
    { text: '>20mm', value: 3},
  ];

  public headers: object[] = [
        { text: 'Sample', value: 'sample_id', cellClass: 'col-sample-id' },
        // { text: 'Patient', value: 'patient_id', cellClass: 'col-sample-id' },
        { text: 'Result', value: 'result', cellClass: 'col-result' },
        // { text: 'Specimen', value: 'specimen_type'},
        { text: 'Node Status', value: 'ln' },
        { text: 'Tumor Size (mm)', value: 'size'},
        { text: 'Comment', value: 'comment' },
        { text: 'Date', value: 'end_time' },
      ];

  @Prop() public readonly tests!: IXIBCSample[];
  public testCount: number = 0;

  public editedIndex: number = -1;
  public defaultItem = {
    guid: '',
    gapdh_ct: 0,
    gusb_ct: 0,
    tfrc_ct: 0,
    foxm1_ct: 0,
    pttg1_ct: 0,
    znf367_ct: 0,
    ln: -1,
    size: -1,
    sample_id: '',
    patient_id: '',
    specimen_type: ' ',
    end_time: '',
    comment: ' ',
    result: '',
    test_result_id: -1,
  };
  public editedItem: IXIBCSample = this.defaultItem;

  public formatTime(timeString: string) {
    return format(parseISO(timeString), 'MM/dd/yyyy h:mmaaa');
  }


  public editItem(item: IXIBCSample) {
    // console.log(item);
    this.editedIndex = this.tests.indexOf(item);
    this.editedItem = item;
  }

  public close() {
    setTimeout(() => {
      this.editedItem = this.defaultItem;
      this.editedIndex = -1;
    }, 300);
  }

  public async save() {
    if (this.editedIndex > -1) {
      // console.log(this.editedItem);
      await api.updateAnnotationForTest(
        readToken(this.$store),
        'comment',
        this.editedItem.comment,
        this.editedItem.test_result_id,
      );
      // Object.assign(this.desserts[this.editedIndex], this.editedItem)
    }
    this.close();
  }

  public mounted() {
    // this.updateTestView();
  }

  public async selectNodeStatus(item, e) {
    // console.log(item);
    // console.log(e);
    await api.updateAnnotationForTest(
      readToken(this.$store),
      'clinicalValue.0',
      `${e}`,
      item.test_result_id,
    );
  }

  public async selectTumorSize(item, e) {
    // console.log(item);
    // console.log(e);
    await api.updateAnnotationForTest(
      readToken(this.$store),
      'clinicalValue.1',
      `${e}`,
      item.test_result_id,
    );
  }

}

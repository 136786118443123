
























































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile, readToken } from '@/store/main/getters';
import { api } from '@/api';
import { ITestResult, ITrace, IAssay, IFilter } from '@/interfaces';

import { subWeeks, startOfToday, parseISO, format, formatISO } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';



import * as Plotly from 'plotly.js';
import { Config, Datum, Layout, PlotData, newPlot, Template } from 'plotly.js';


@Component
export default class Dashboard extends Vue {

  public testResults: ITestResult[] = [];
  public testCount: number = 0;
  public testCountWeek: number = 0;
  public testCountMonth: number = 0;

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }

  get testsToday() {
    const tests = this.testResults.filter((t: ITestResult) => parseISO(`${t.created_at}Z`) > startOfToday());
    return tests;
  }

  public async loadTestResults() {
    const response = await api.getLatestTestResults(readToken(this.$store), 7);
    if (response) {
      console.log('Loaded test result');
      this.testResults = response.data;
    }
  }

  public async getTestCount(filters: IFilter[] = []) {
    const response = await api.getTestsCount(readToken(this.$store), filters);
    if (response) {
      console.log(`Loaded test count: ${response.data}`);
      return response.data;
    }
    return -1;
  }

  public async mounted() {
    await this.loadTestResults();
    this.testCount = await this.getTestCount();
    this.testCountWeek = await this.getTestCount([{field: 'created_at', operator: 'after', value: formatISO(subWeeks(startOfToday(), 1))}]);
    this.testCountMonth = await this.getTestCount([{field: 'created_at', operator: 'after', value: formatISO(subWeeks(startOfToday(), 4))}]);

    const testCounts = {};
    const assayCount = new Set()
    for (const tr of this.testResults) {
      assayCount.add(tr.assay_name)
      if (testCounts[tr.assay_name]) {
        testCounts[tr.assay_name] += 1;
      } else {
        testCounts[tr.assay_name] = 1;
      }
    }

    console.log(testCounts);

    Plotly.newPlot('assayChart', [
      {
        y: Object.keys(testCounts) as Plotly.Datum[],
        x: Object.values(testCounts) as Plotly.Datum[],
        type: 'bar',
        orientation: 'h',
      },
    ], {
        title: 'Tests by Assay for the last 7 days',
        height: assayCount.size * 50 + 200,
        yaxis: {
            //title: 'Assay',
            showgrid: false,
            zeroline: false,
        },
        xaxis: {
            title: 'Count',
            showline: false,
            showgrid: false,
            zeroline: false,
        },
        margin: {
          l: 200,
        },
    });

    const noResult = this.testResults.filter((t: ITestResult) => t.result === 'NO RESULT' );
    const error = this.testResults.filter((t: ITestResult) => t.result === 'ERROR' );
    const unresolved = this.testResults.filter((t: ITestResult) => t.result === 'UNRESOLVED' );

    const completed = this.testResults.filter((t: ITestResult) => t.status === 'Done' );
    const aborted = this.testResults.filter((t: ITestResult) => t.status === 'Aborted' );


    const testsByDate = {};
    for (const tr of this.testResults) {
      const date = format(
        utcToZonedTime(parseISO(`${tr.created_at}Z`), 'America/Los_Angeles'),
        'yyyy/MM/dd',
      );
      if (testsByDate[date]) {
        testsByDate[date] += 1;
      } else {
        testsByDate[date] = 1;
      }
    }

    console.log(testsByDate);

    const dateKeys = Object.keys(testsByDate).sort();

    const runningSum = {};
    dateKeys.map((el, i) => {
      let initial = 0;
      if (i > 0) {
        initial = runningSum[dateKeys[i - 1]];
      }
      runningSum[el] = initial + testsByDate[el];
    });

    const previousTestCount = this.testCount - runningSum[dateKeys[dateKeys.length - 1]];

    const testSumTrace: number[] = [];
    const testCountTrace: number[] = [];

    for (const date of dateKeys) {
      testSumTrace.push(runningSum[date] + previousTestCount);
      testCountTrace.push(testsByDate[date]);
    }

    const trace1 = {
      x: dateKeys as Plotly.Datum[],
      y: testSumTrace as Plotly.Datum[],
      type: 'scatter',
      yaxis: 'y2',
      name: 'Cumulative Test Count',
    };

    const trace2 = {
      x: dateKeys as Plotly.Datum[],
      y: testCountTrace as Plotly.Datum[],
      type: 'bar',
      name: 'Tests per Day',
    };

    const data = [trace1, trace2] as Plotly.Data[];

    const layout = {
      title: 'Tests over the last 7 days',
      yaxis: {title: 'Count'},
      yaxis2: {
        title: 'Count',
        overlaying: 'y',
        side: 'right',
      },
    } as Plotly.Layout;

    Plotly.newPlot('dateChart', data, layout);


  }
}

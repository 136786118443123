var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tests.length > 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tests,"item-key":"guid","dense":true,"items-per-page":-1,"show-select":true},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":(!isNaN(item.ln) && !isNaN(item.size)) && isSelected,"readonly":isNaN(item.ln) || isNaN(item.size),"disabled":isNaN(item.ln) || isNaN(item.size)},on:{"input":function($event){return select($event)}}})]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(item.guid === _vm.editedItem.guid)?_c('v-text-field',{attrs:{"hide-details":true,"dense":"","single-line":"","autofocus":true},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}}):_c('span',[_vm._v(_vm._s(item.comment))]),(item.guid === _vm.editedItem.guid)?_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"red"},on:{"click":_vm.close}},[_vm._v(" mdi-window-close ")]),_c('v-icon',{attrs:{"color":"green"},on:{"click":_vm.save}},[_vm._v(" mdi-content-save ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"green"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"item.ln",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-select',{attrs:{"items":_vm.nodeSelect,"label":"Node Status","dense":"","value":value,"single-line":true},on:{"change":function($event){return _vm.selectNodeStatus(item, $event)}},model:{value:(item.ln),callback:function ($$v) {_vm.$set(item, "ln", $$v)},expression:"item.ln"}})]}},{key:"item.size",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-select',{attrs:{"items":_vm.tumorSelect,"label":"Tumor Size","dense":"","value":value,"single-line":true},on:{"change":function($event){return _vm.selectTumorSize(item, $event)}},model:{value:(item.size),callback:function ($$v) {_vm.$set(item, "size", $$v)},expression:"item.size"}})]}},{key:"item.end_time",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatTime(value))+" ")]}}],null,false,2062854725),model:{value:(_vm.selectedTests),callback:function ($$v) {_vm.selectedTests=$$v},expression:"selectedTests"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
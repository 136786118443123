






























import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readToken } from '@/store/main/getters';
import { Socket } from 'vue-socket.io-extended';

import { appName, apiUrl } from '@/env';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer, commitAddNotification } from '@/store/main/mutations';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(FilePondPluginFileValidateType);

import ClinicalArchive from '@/components/ClinicalArchive.vue';

import { api } from '@/api';
import { dispatchCheckApiError } from '@/store/main/actions';
import FileSaver from 'file-saver';

import { IXIBCSample, IFile } from '@/interfaces';


interface IColumn {
  name: string;
  value: number;
}

interface IExtraAnalyte {
  analyte: string;
  key: string;
  value: string;
  data: IColumn[];
}

interface IExtraData {
  key: string;
  value: string;
  data: IColumn[];
}

@Component({
  components: {
    FilePond,
    ClinicalArchive,
  },
})
export default class Dashboard extends Vue {


  public disableReport = false;
  public disableSummary = false;

  public tests: IXIBCSample[] = [];
  public selectedTests: IXIBCSample[] = [];

  public async mounted() {
    /*const clinicalData: IXIBCSample[] = await api.getClinicalDataForFile(
      readToken(this.$store),
      110,
      )
    this.tests = clinicalData;
    console.log(this.tests);*/
  }

  @Socket() // --> listens to the event by method name, e.g. `connect`
  public connect() {
    // console.log('connection established');
    // Join a room here?
  }

  @Socket('new-archive')
  public async onFileUpload(fileInfo: string) {
    // console.log('new-archive')
    // console.log(fileInfo);
    const file: IFile = JSON.parse(fileInfo);
    const clinicalData: IXIBCSample[] = await api.getClinicalDataForFile(
      readToken(this.$store),
      file.id,
    );
    this.tests = clinicalData;
    /* commitAddNotification(this.$store, {
        content: `${fileInfo.file_type}, ${fileInfo.test_count} tests, ${fileInfo.new_files} new`,
        color: 'success'
    }); */
    // this.updateTestView();
    // commitSetTest(this.$store, JSON.parse(testInfo));
  }

  public async downloadReport() {
      try {
          const response = await api.getXIBCReport(readToken(this.$store), this.selectedTests);
          if (response) {
              const fileNameHeader = 'x-suggested-filename';
              const suggestedFileName = response.headers[fileNameHeader];
              const effectiveFileName = (suggestedFileName === undefined
                          ? 'XpertInsight_BC_report.pdf'
                          : suggestedFileName);
              FileSaver.saveAs(response.data, effectiveFileName);


          }
      } catch (error: any) {
          await dispatchCheckApiError(this.$store, error);
      }
  }

  get selectedTestResultIds() {
    return this.selectedTests.map((t) => t.test_result_id as number);
  }

  public handleFilePondInit() {
    // console.log('FilePond has initialized');
  }

  public async downloadSummary() {

    this.disableSummary = true;

    // The results are round-tripped to the server for simplicity,
    // any fields added here need to be configured to be sent back from the /insight endpoint
    const results = await api.getXIBCData(readToken(this.$store), this.selectedTests);

    const extraAnalytes: IExtraAnalyte[] = results.map((r) => ({
        analyte: 'Insight', key: 'guid', value: r.guid,
        data: [
          {name: 'MRS', value: r.mrs || 0},
          {name: 'CRS', value: r.crs || 0},
          {name: 'znf367_dct', value: r.znf367_dct || 0},
          {name: 'pttg1_dct', value: r.pttg1_dct || 0},
          {name: 'foxm1_dct', value: r.foxm1_dct || 0},
          {name: 'norm_ct', value: r.norm_ct || 0},
          {name: 'ln', value: r.ln || 0},
          {name: 'size', value: r.size || 0},
        ],
      }),
    );
    // console.log(extraAnalytes);
    try {
        const response = await api.getTestSummary(readToken(this.$store), {
          tests: this.selectedTestResultIds, format: 'EXCEL',
          analytes: [], readings: [], groupBy: 'PER_ASSAY', extraAnalytes,
        });
        if (response) {
            const fileNameHeader = 'x-suggested-filename';
            const suggestedFileName = response.headers[fileNameHeader];
            const effectiveFileName = (suggestedFileName === undefined
                        ? 'xibc_summary.xlsx'
                        : suggestedFileName);
            FileSaver.saveAs(response.data, effectiveFileName);
        }
    } catch (error: any) {
        // console.log(error);
        await dispatchCheckApiError(this.$store, error);
    }
    this.disableSummary = false;
  }

  public handleFilePondAddFile() {
    // console.log('add file');
    this.tests = [];
    this.selectedTests = [];
  }

  public handleFilePondProcess(error, file) {
    // console.log(error);
    /*if (error.code === 415) {
      commitAddNotification(this.$store, { content: 'Not a valid GeneXpert Dx File', color: 'error' });
    }*/
  }

  public get serverOptions() {
    return {
      url: `${apiUrl}/api/v1/files/upload/`,
      headers: {Authorization: `Bearer ${readToken(this.$store)}`},
    };
  }

}
